export const GET = "GET"
export const POST = "POST"
export const PUT = "PUT"
export const PATCH = "PATCH"
export const DELETE = "DELETE"
export const MIN_CART_VALUE = "minCartValue"
export const EXPRESS_DELIVERY_CHARGE = "expressDeliveryCharge"
export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID
export const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET
export const REACT_APP_GEO_LOCATION = process.env.REACT_APP_GEO_LOCATION


//color for restock sheet - This color is used as status
export const RED = "red"
export const YELLOW = "yellow"
export const GREEN = "green"
export const PURPLE = "purple"
export const GREY = "grey"
export const WHITE = "white"

//pages which need to be opened in new page
export const pages = [
    "Restock Page Explained",
    "FBA Restock History",
    "Shipping Queue",
    "Inventory Status",
    "Out of Stock Report"
]

//Roles
export const SUPERADMIN = "SUPERADMIN"
export const SUPERUSER = "SUPERUSER"
export const CLIENTUSER = "USER"