import React, { useEffect, useState } from "react"
import { Button, Col, InputGroup, Modal, ModalBody, Row } from "reactstrap"
import { useHistory, useLocation } from "react-router-dom"
import { useDispatch } from "react-redux"
import {
  format_FAP,
  format_FPI,
  format_FBCT,
  format_OFBA,
  format_OFBM,
  format_ATSI,
  format_RFUO,
  format_DDD,
  format_RA,
  format_UTM,
  format_FSA,
  format_NOTES,
  format_status,
  format_CR,
} from "./ColumnFormat"
import {
  addItemToRestockSheet,
  deleteItemFromRestockSheet,
  deleteRestockSheet,
  doneRestockSheet,
  getOnlyFbmSku,
  restockSheetDataById,
} from "store/action/stock"
import { setProductExpiryDate } from "store/action/product"
import { useToasts } from "react-toast-notifications"
import DataTable from "react-data-table-component"
import { MultiSelect } from "react-multi-select-component"
import { GREEN, GREY, PURPLE, RED, WHITE, YELLOW } from "utils/constants"
import { statusProductTag } from "store/action/product"
import { setStartLoader, setStopLoader } from "store/loader/action"
import "../../assets/scss/custom/rdt.scss"
import "./dashboard.scss"
import RefreshIcon from "../../assets/images/refresh.svg"
import DeleteIcon from "../../assets/images/delete.svg"
import FinalizeIcon from "../../assets/images/finalize.svg"
import PrintIcon from "../../assets/images/print.svg"
import Pagination from "../../components/Common/Pagination"
import moment from "moment"
import { BASE_URL } from "utils/url"
import { Tooltip } from "react-tooltip"
import "react-tooltip/dist/react-tooltip.css"

const Restock = () => {
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const { addToast } = useToasts()
  const [restock, setRestockData] = useState([])
  const [restockOriginal, setRestockOriginalData] = useState([])
  const [sortedRestockSheetData, setSortedRestockSheetData] = useState([])
  const [filterValue, setFilterValue] = useState("")
  const [updatedValue, setUpdatedValue] = useState({})
  const [, setRowId] = useState("")
  const [restockSheetDoneClick, setRestockSheetDoneClick] = useState(false)
  const [restockSheetDeleteClick, setRestockSheetDeleteClick] = useState(false)
  const [resetPaginationToggle, setResetPaginationToggle] = useState(true)
  const [selected, setSelected] = useState([])
  const [buyboxprice, setBuyboxprice] = useState({})
  const [restockSheetSort, setRestockSheetSort] = useState({
    status: 0,
    expiryDate: 0,
    qty: 0,
    fbmsku: 0,
    fbasku: 0,
    productName: 0,
    category: 0,
    rank: 0,
    str: 0,
    available: 0,
    inbound: 0,
    total: 0,
    daysRemaining: 0,
    daysOOS: 0,
    wps: 0,
    min: 0,
    max: 0,
  })
  const [sortByDays, setSortByDays] = useState({ fbm: 0, fba: 0 })
  const [rowsPerPage, setRowsPerPage] = useState(100)
  const [currentPage, setCurrentPage] = useState(0)
  const [invLimit, setInvLimit] = useState({
    ivLimit: 0,
    ovLimit: 0,
    totSku: 0,
    totalQty: 0,
  })
  const [filteredData, setFilteredData] = useState([])
  useEffect(() => {
    if (localStorage.getItem("marketplaceId")) {
      getRestockSheetDataById()
    }
  }, [localStorage.getItem("marketplaceId")])

  useEffect(() => {
    if (selected.length > 0 && filterValue !== "") {
      let status = selected.reduce(function (previousValue, currentValue) {
        return [...previousValue, currentValue.value]
      }, [])
      let filteredDataFromStatus = [...restockOriginal].filter(item =>
        status.includes(item.status)
      )
      let filterData = [...filteredDataFromStatus].filter(
        item =>
          item.tag.toLowerCase().includes(filterValue.trim().toLowerCase()) ||
          item.name.toLowerCase().includes(filterValue.trim().toLowerCase()) ||
          item.fbmSku
            .toLowerCase()
            .includes(filterValue.trim().toLowerCase()) ||
          item.fbaSku
            .toLowerCase()
            .includes(filterValue.trim().toLowerCase()) ||
          item.asin.toLowerCase().includes(filterValue.trim().toLowerCase())
      )
      setRestockData(filterData)
      setFilteredData(filterData)
      setSortedRestockSheetData([])
    } else if (selected.length > 0) {
      let status = selected.reduce(function (previousValue, currentValue) {
        return [...previousValue, currentValue.value]
      }, [])

      let filterData = [...restockOriginal].filter(item =>
        status.includes(item.status)
      )
      setRestockData(filterData)
      setFilteredData(filterData)
      setSortedRestockSheetData([])
    } else if (filterValue !== "") {
      let filterData = [...restockOriginal].filter(
        item =>
          item.tag.toLowerCase().includes(filterValue.trim().toLowerCase()) ||
          item.name.toLowerCase().includes(filterValue.trim().toLowerCase()) ||
          item.fbmSku
            .toLowerCase()
            .includes(filterValue.trim().toLowerCase()) ||
          item.fbaSku
            .toLowerCase()
            .includes(filterValue.trim().toLowerCase()) ||
          item.asin.toLowerCase().includes(filterValue.trim().toLowerCase())
      )
      setRestockData(filterData)
      setFilteredData(filterData)
      setSortedRestockSheetData([])
    } else {
      let tempData = [...restockOriginal]
      setRestockData(tempData)
    }
  }, [selected, filterValue])

  useEffect(() => {
    // when using client-side pagination we can just slice the rows set
    //const lastIndex = (currentPage + 1) * rowsPerPage;
    //const firstIndex = lastIndex - rowsPerPage;
    //var data = restockOriginal.slice(firstIndex, Math.min(lastIndex, restockOriginal.length));

    const lastIndex = ((currentPage + 1) * rowsPerPage)
    const firstIndex = lastIndex - rowsPerPage;
    var data = restockOriginal.slice(firstIndex, Math.min(lastIndex,restockOriginal.length));

    setRestockData(data);
  }, [currentPage, rowsPerPage]);

  const getRestockSheetDataById = () => {
    dispatch(
      restockSheetDataById(
        // { data: { pageNo: currentPage, pageSize: perPage } }
        { id: location?.state?.id },
        responseData => {
          var data = responseData.data.sku.slice(0, Math.min(rowsPerPage,responseData.data.sku.length));
          setRestockData(data)
          setRestockOriginalData(responseData.data.sku)
          setInvLimit(states => {
            return {
              ivLimit: responseData.data.inventoryLimit,
              ovLimit: responseData.data.oversizedLimit,
              totSku: responseData.data.totalSku,
              totalQty: responseData.data.totalQty,
            }
          })
          setUpdatedValue({})
          /* if (responseData.data.sku.length > 0) {
            let tempFBAsku = []
            responseData.data.sku.filter(item => tempFBAsku.push(item.fbaSku))
            dispatch(
              getBuyBoxPrice(
                { data: tempFBAsku },
                response => {
                  setBuyboxprice(response.data)
                },
                errorData => {
                  addToast(errorData.response.data.error.displayMessage, {
                    appearance: "error",
                    autoDismiss: true,
                  })
                }
              )
            )
          } */
        },
        errorData => {
          setUpdatedValue({})
          addToast(errorData.response.data.error.displayMessage, {
            appearance: "error",
            autoDismiss: true,
          })
        }
      )
    )
  }

  const options = [
    { label: "Red", value: "red" },
    { label: "Yellow", value: "yellow" },
    { label: "Green", value: "green" },
    { label: "Purple", value: "purple" },
    { label: "Grey", value: "grey" },
    { label: "White", value: "white" },
  ]

  //custom sort function
  const customShipmentAmountSort = () => {
    setRestockSheetSort(prevState => {
      return { ...prevState, qty: prevState.qty + 1 }
    })
    actualSort("shipmentCount")
  }
  const customExpiryDateSort = () => {
    setRestockSheetSort(prevState => {
      return { ...prevState, expiryDate: prevState.expiryDate + 1 }
    })
    actualSort("ExpDate")
  }
  const customSortByStatus = () => {
    setRestockSheetSort(prevState => {
      return { ...prevState, status: prevState.status + 1 }
    })
    actualSort("status")
  }
  const customSortByFbmSku = () => {
    setRestockSheetSort(prevState => {
      return { ...prevState, fbmsku: prevState.fbmsku + 1 }
    })
    actualSort("FbmSku")
  }
  const customSortByFbaSku = () => {
    setRestockSheetSort(prevState => {
      return { ...prevState, fbasku: prevState.fbasku + 1 }
    })
    actualSort("FbaSku")
  }
  const customSortByProductName = () => {
    setRestockSheetSort(prevState => {
      return { ...prevState, productName: prevState.productName + 1 }
    })
    actualSort("productName")
  }
  const customSortByCategory = () => {
    setRestockSheetSort(prevState => {
      return { ...prevState, category: prevState.category + 1 }
    })
    actualSort("category")
  }
  const customSortByRank = () => {
    setRestockSheetSort(prevState => {
      return { ...prevState, rank: prevState.rank + 1 }
    })
    actualSort("rank")
  }
  const customSortBySTR = () => {
    setRestockSheetSort(prevState => {
      return { ...prevState, str: prevState.str + 1 }
    })
    actualSort("str")
  }
  const customSortByAvailable = () => {
    setRestockSheetSort(prevState => {
      return { ...prevState, available: prevState.available + 1 }
    })
    actualSort("available")
  }
  const customSortByInbound = () => {
    setRestockSheetSort(prevState => {
      return { ...prevState, inbound: prevState.inbound + 1 }
    })
    actualSort("inbound")
  }
  const customSortByTotal = () => {
    setRestockSheetSort(prevState => {
      return { ...prevState, total: prevState.total + 1 }
    })
    actualSort("total")
  }
  const customSortByDaysRemaining = () => {
    setRestockSheetSort(prevState => {
      return { ...prevState, daysRemaining: prevState.daysRemaining + 1 }
    })
    actualSort("daysRemaining")
  }
  const customSortByDaysOOS = () => {
    setRestockSheetSort(prevState => {
      return { ...prevState, daysOOS: prevState.daysOOS + 1 }
    })
    actualSort("daysOOS")
  }
  const customSortByWPS = () => {
    setRestockSheetSort(prevState => {
      return { ...prevState, wps: prevState.wps + 1 }
    })
    actualSort("wps")
  }
  const customSortByMin = () => {
    setRestockSheetSort(prevState => {
      return { ...prevState, min: prevState.min + 1 }
    })
    actualSort("min")
  }
  const customSortByMax = () => {
    setRestockSheetSort(prevState => {
      return { ...prevState, max: prevState.max + 1 }
    })
    actualSort("max")
  }

  //it will perform actual sorting based on type provided in customSort()
  const actualSort = type => {
    switch (type) {
      case "shipmentCount":
        let qtyData = sortedRestockSheetData.length
          ? selected.length > 0 || filterValue !== ""
            ? [...filteredData]
            : [...sortedRestockSheetData]
          : selected.length > 0 || filterValue !== ""
          ? [...filteredData]
          : [...restockOriginal]
        let dataWithQty = qtyData
          ?.filter(item => item.shipmentQty > 0)
          .sort((a, b) => {
            return restockSheetSort.qty % 2
              ? b.shipmentQty - a.shipmentQty
              : a.shipmentQty - b.shipmentQty
          })
        let dataWithoutQty = qtyData?.filter(item => item.shipmentQty === 0)
        if (restockSheetSort.qty % 2 !== 0) {
          setRestockData([...(dataWithQty || []), ...(dataWithoutQty || [])])
          setSortedRestockSheetData([
            ...(dataWithQty || []),
            ...(dataWithoutQty || []),
          ])
        } else {
          setRestockData([...(dataWithQty || []), ...(dataWithoutQty || [])])
          setSortedRestockSheetData([
            ...(dataWithQty || []),
            ...(dataWithoutQty || []),
          ])
        }
        break
      case "status":
        let statusData = sortedRestockSheetData.length
          ? [...sortedRestockSheetData]
          : selected.length > 0 || filterValue !== ""
          ? [...filteredData]
          : [...restockOriginal]
        let redData = statusData?.filter(item => item.status === RED)
        let yellowData = statusData?.filter(item => item.status === YELLOW)
        let greenData = statusData?.filter(item => item.status === GREEN)
        let purpleData = statusData?.filter(item => item.status === PURPLE)
        let greyData = statusData?.filter(item => item.status === GREY)
        let whiteData = statusData?.filter(item => item.status === WHITE)
        if (restockSheetSort.status % 2 === 0) {
          setRestockData([
            ...(redData || []),
            ...(yellowData || []),
            ...(greenData || []),
            ...(purpleData || []),
            ...(greyData || []),
            ...(whiteData || []),
          ])
          setSortedRestockSheetData([
            ...(redData || []),
            ...(yellowData || []),
            ...(greenData || []),
            ...(purpleData || []),
            ...(greyData || []),
            ...(whiteData || []),
          ])
        } else {
          setRestockData([
            ...(whiteData || []),
            ...(greyData || []),
            ...(purpleData || []),
            ...(greenData || []),
            ...(yellowData || []),
            ...(redData || []),
          ])
          setSortedRestockSheetData([
            ...(whiteData || []),
            ...(greyData || []),
            ...(purpleData || []),
            ...(greenData || []),
            ...(yellowData || []),
            ...(redData || []),
          ])
        }
        break
      case "ExpDate":
        let expDateData = sortedRestockSheetData.length
          ? [...sortedRestockSheetData]
          : selected.length > 0
          ? [...filteredData]
          : [...restockOriginal]

        //filter out expiry date data
        let expDateWithNA = expDateData.filter(date => date.expiryDate === "")
        let expDateWithoutNA = expDateData.filter(
          date => date.expiryDate !== ""
        )

        //apply sort on data which has expiration date
        let sortedData = expDateWithoutNA.sort((dateOne, dateTwo) => {
          let comparison = 0
          let rowA = new Date(dateOne.expiryDate)
          let rowB = new Date(dateTwo.expiryDate)
          if (rowA.getTime() > rowB.getTime()) {
            comparison = 1
          } else if (rowA.getTime() < rowB.getTime()) {
            comparison = -1
          }
          return restockSheetSort.expiryDate % 2 === 0
            ? comparison * -1
            : comparison
        })

        //reset pageination
        setResetPaginationToggle(true)

        setRestockData([...(sortedData || []), ...(expDateWithNA || [])])
        setSortedRestockSheetData([
          ...(sortedData || []),
          ...(expDateWithNA || []),
        ])
        break
      case "FbmSku":
        let fbmskuData = sortedRestockSheetData.length
          ? [...sortedRestockSheetData]
          : selected.length > 0
          ? [...filteredData]
          : [...restockOriginal]
        let sortedfbmsku = fbmskuData.sort((dataOne, dataTwo) => {
          let comparison = 0
          let rowA = dataOne.fbmSku
          let rowB = dataTwo.fbmSku
          if (rowA > rowB) {
            comparison = 1
          } else if (rowA < rowB) {
            comparison = -1
          }
          return restockSheetSort.fbmsku % 2 === 0
            ? comparison * -1
            : comparison
        })
        setRestockData(sortedfbmsku)
        setSortedRestockSheetData(sortedfbmsku)
        break
      case "FbaSku":
        let fbaskuData = sortedRestockSheetData.length
          ? [...sortedRestockSheetData]
          : selected.length > 0
          ? [...filteredData]
          : [...restockOriginal]
        let sortedfbasku = fbaskuData.sort((dataOne, dataTwo) => {
          let comparison = 0
          let rowA = dataOne.fbaSku
          let rowB = dataTwo.fbaSku
          if (rowA > rowB) {
            comparison = 1
          } else if (rowA < rowB) {
            comparison = -1
          }
          return restockSheetSort.fbasku % 2 === 0
            ? comparison * -1
            : comparison
        })
        setRestockData(sortedfbasku)
        setSortedRestockSheetData(sortedfbasku)
        break
      case "productName":
        let productNameData = sortedRestockSheetData.length
          ? [...sortedRestockSheetData]
          : selected.length > 0
          ? [...filteredData]
          : [...restockOriginal]
        let sortedproductName = productNameData.sort((dataOne, dataTwo) => {
          let comparison = 0
          let rowA = dataOne.name
          let rowB = dataTwo.name
          if (rowA > rowB) {
            comparison = 1
          } else if (rowA < rowB) {
            comparison = -1
          }
          return restockSheetSort.productName % 2 === 0
            ? comparison * -1
            : comparison
        })
        setRestockData(sortedproductName)
        setSortedRestockSheetData(sortedproductName)
        break
      case "category":
        let categoryData = sortedRestockSheetData.length
          ? [...sortedRestockSheetData]
          : selected.length > 0
          ? [...filteredData]
          : [...restockOriginal]
        let sortedcategory = categoryData.sort((dataOne, dataTwo) => {
          let comparison = 0
          let rowA = dataOne.category
          let rowB = dataTwo.category
          if (rowA > rowB) {
            comparison = 1
          } else if (rowA < rowB) {
            comparison = -1
          }
          return restockSheetSort.category % 2 === 0
            ? comparison * -1
            : comparison
        })
        setRestockData(sortedcategory)
        setSortedRestockSheetData(sortedcategory)
        break
      case "rank":
        let rankData = sortedRestockSheetData.length
          ? [...sortedRestockSheetData]
          : selected.length > 0
          ? [...filteredData]
          : [...restockOriginal]

        let sortedrank = rankData.sort((dataOne, dataTwo) => {
          let comparison = 0
          let rowB = parseInt(dataTwo.rank.replace(/,/g, ""))
          let rowA = parseInt(dataOne.rank.replace(/,/g, ""))
          if (rowA > rowB) {
            comparison = 1
          } else if (rowA < rowB) {
            comparison = -1
          }
          return restockSheetSort.rank % 2 === 0 ? comparison * -1 : comparison
        })
        setRestockData(sortedrank)
        setSortedRestockSheetData(sortedrank)
        break
      case "str":
        let strData = sortedRestockSheetData.length
          ? [...sortedRestockSheetData]
          : selected.length > 0
          ? [...filteredData]
          : [...restockOriginal]
        let sortedStr = strData.sort((dataOne, dataTwo) => {
          let comparison = 0
          let rowA = dataOne.str
          let rowB = dataTwo.str
          if (rowA > rowB) {
            comparison = 1
          } else if (rowA < rowB) {
            comparison = -1
          }
          return restockSheetSort.str % 2 === 0 ? comparison * -1 : comparison
        })
        setRestockData(sortedStr)
        setSortedRestockSheetData(sortedStr)
        break
      case "available":
        let availableData = sortedRestockSheetData.length
          ? [...sortedRestockSheetData]
          : selected.length > 0
          ? [...filteredData]
          : [...restockOriginal]
        let sortedAvailable = availableData.sort((dataOne, dataTwo) => {
          let comparison = 0
          let rowA = dataOne.availableQty
          let rowB = dataTwo.availableQty
          if (rowA > rowB) {
            comparison = 1
          } else if (rowA < rowB) {
            comparison = -1
          }
          return restockSheetSort.available % 2 === 0
            ? comparison * -1
            : comparison
        })
        setRestockData(sortedAvailable)
        setSortedRestockSheetData(sortedAvailable)
        break
      case "inbound":
        let inboundData = sortedRestockSheetData.length
          ? [...sortedRestockSheetData]
          : selected.length > 0
          ? [...filteredData]
          : [...restockOriginal]
        let sortedInbound = inboundData.sort((dataOne, dataTwo) => {
          let comparison = 0
          let rowA = dataOne.inboundQty
          let rowB = dataTwo.inboundQty
          if (rowA > rowB) {
            comparison = 1
          } else if (rowA < rowB) {
            comparison = -1
          }
          return restockSheetSort.inbound % 2 === 0
            ? comparison * -1
            : comparison
        })
        setRestockData(sortedInbound)
        setSortedRestockSheetData(sortedInbound)
        break
      case "total":
        let totalData = sortedRestockSheetData.length
          ? [...sortedRestockSheetData]
          : selected.length > 0
          ? [...filteredData]
          : [...restockOriginal]
        let sortedTotal = totalData.sort((dataOne, dataTwo) => {
          let comparison = 0
          let rowA = dataOne.totalQty
          let rowB = dataTwo.totalQty
          if (rowA > rowB) {
            comparison = 1
          } else if (rowA < rowB) {
            comparison = -1
          }
          return restockSheetSort.total % 2 === 0 ? comparison * -1 : comparison
        })
        setRestockData(sortedTotal)
        setSortedRestockSheetData(sortedTotal)
        break
      case "daysRemaining":
        let daysRemaining = sortedRestockSheetData.length
          ? [...sortedRestockSheetData]
          : selected.length > 0
          ? [...filteredData]
          : [...restockOriginal]
        let sortedDaysRemaining = daysRemaining.sort((dataOne, dataTwo) => {
          let comparison = 0
          let rowA = dataOne.daysRemaining
          let rowB = dataTwo.daysRemaining
          if (rowA > rowB) {
            comparison = 1
          } else if (rowA < rowB) {
            comparison = -1
          }
          return restockSheetSort.daysRemaining % 2 === 0
            ? comparison * -1
            : comparison
        })
        setRestockData(sortedDaysRemaining)
        setSortedRestockSheetData(sortedDaysRemaining)
        break
      case "daysOOS":
        let daysOOSData = sortedRestockSheetData.length
          ? [...sortedRestockSheetData]
          : selected.length > 0
          ? [...filteredData]
          : [...restockOriginal]
        let sorteddaysOOS = daysOOSData.sort((dataOne, dataTwo) => {
          let comparison = 0
          let rowA = dataOne.daysOOS
          let rowB = dataTwo.daysOOS
          if (rowA > rowB) {
            comparison = 1
          } else if (rowA < rowB) {
            comparison = -1
          }
          return restockSheetSort.daysOOS % 2 === 0
            ? comparison * -1
            : comparison
        })
        setRestockData(sorteddaysOOS)
        setSortedRestockSheetData(sorteddaysOOS)
        break
      case "wps":
        let wpsData = sortedRestockSheetData.length
          ? [...sortedRestockSheetData]
          : selected.length > 0
          ? [...filteredData]
          : [...restockOriginal]
        let sortedWps = wpsData.sort((dataOne, dataTwo) => {
          let comparison = 0
          let rowA = Math.round(dataOne.wps * 100)
          let rowB = Math.round(dataTwo.wps * 100)
          if (rowA > rowB) {
            comparison = 1
          } else if (rowA < rowB) {
            comparison = -1
          }
          return restockSheetSort.wps % 2 === 0 ? comparison * -1 : comparison
        })
        setRestockData(sortedWps)
        setSortedRestockSheetData(sortedWps)
        break
      case "min":
        let minData = sortedRestockSheetData.length
          ? [...sortedRestockSheetData]
          : selected.length > 0
          ? [...filteredData]
          : [...restockOriginal]
        let sortedMin = minData.sort((dataOne, dataTwo) => {
          let comparison = 0
          let rowA = dataOne.suggestedUnitMin
          let rowB = dataTwo.suggestedUnitMin
          if (rowA > rowB) {
            comparison = 1
          } else if (rowA < rowB) {
            comparison = -1
          }
          return restockSheetSort.min % 2 === 0 ? comparison * -1 : comparison
        })
        setRestockData(sortedMin)
        setSortedRestockSheetData(sortedMin)
        break
      case "max":
        let maxData = sortedRestockSheetData.length
          ? [...sortedRestockSheetData]
          : selected.length > 0
          ? [...filteredData]
          : [...restockOriginal]
        let sortedMax = maxData.sort((dataOne, dataTwo) => {
          let comparison = 0
          let rowA = dataOne.suggestedUnitMax
          let rowB = dataTwo.suggestedUnitMax
          if (rowA > rowB) {
            comparison = 1
          } else if (rowA < rowB) {
            comparison = -1
          }
          return restockSheetSort.max % 2 === 0 ? comparison * -1 : comparison
        })
        setRestockData(sortedMax)
        setSortedRestockSheetData(sortedMax)
        break
      default:
        break
    }
  }

  const customSortByDays = days => {
    if (days.includes("fbm")) {
      setSortByDays(prevState => {
        return { ...prevState, fbm: prevState.fbm + 1 }
      })
      let prepareSortData = sortedRestockSheetData.length
        ? [...sortedRestockSheetData]
        : selected.length > 0 || filterValue !== ""
        ? [...filteredData]
        : [...restockOriginal]

      let sortedData = prepareSortData.sort((firstData, secondData) => {
        let comparison = 0
        if (firstData[days] > secondData[days]) {
          comparison = 1
        } else if (firstData[days] < secondData[days]) {
          comparison = -1
        }
        return sortByDays.fbm % 2 === 0 ? comparison * -1 : comparison
      })
      setRestockData(sortedData)
      setSortedRestockSheetData(sortedData)
    } else {
      setSortByDays(prevState => {
        return { ...prevState, fba: prevState.fba + 1 }
      })
      let prepareSortData = sortedRestockSheetData.length
        ? [...sortedRestockSheetData]
        : selected.length > 0 || filterValue !== ""
        ? [...filteredData]
        : [...restockOriginal]

      let sortedData = prepareSortData.sort((firstData, secondData) => {
        let comparison = 0
        if (firstData[days] > secondData[days]) {
          comparison = 1
        } else if (firstData[days] < secondData[days]) {
          comparison = -1
        }
        return sortByDays.fba % 2 === 0 ? comparison * -1 : comparison
      })
      setRestockData(sortedData)
      setSortedRestockSheetData(sortedData)
    }
  }

  const columns = [
    {
      name: (
        <div className="d-flex flex-column">
          <div>Hide</div>
          <div
            className="text-primary pointer col-head-color"
            id="custom-sort-status"
            onMouseOver={() => {
              let element = document.getElementById("custom-sort-status")
              element.classList.add("text-warning")
            }}
            onMouseOut={() => {
              let element = document.getElementById("custom-sort-status")
              element.classList.add("text-primary")
              element.classList.remove("text-warning")
            }}
            onClick={() => {
              customSortByStatus()
              let element = document.getElementById("custom-sort-status")
              element.classList.add("text-warning")
            }}
          >
            Status
          </div>

          <span
            className="info-icon"
            data-toggle="tooltip"
            data-tooltip-id="my-tooltip"
            data-tooltip-html="Hide – Move button to right to hide this item <br /><br />Status – Click to sort by urgency of restock needed"
          >
            <i className="bx bx-info-circle text-primary font-weight-bold h5"></i>
            <Tooltip
              id="my-tooltip"
              place="top"
              style={{ zIndex: "9999", textAlign: "left", maxWidth: "600px" }}
            />
          </span>
        </div>
      ),
      selector: row => row.Status,
      cell: row => format_status(row, changeStatus),
      width: "90px",
      grow: 0,
    },
    {
      name: (
        <div className="d-flex flex-column">
          <div
            className="text-primary pointer col-head-color"
            id="custom-sort-fbm-sku"
            onMouseOver={() => {
              let element = document.getElementById("custom-sort-fbm-sku")
              element.classList.add("text-warning")
            }}
            onMouseOut={() => {
              let element = document.getElementById("custom-sort-fbm-sku")
              element.classList.add("text-primary")
              element.classList.remove("text-warning")
            }}
            onClick={() => {
              customSortByFbmSku()
              let element = document.getElementById("custom-sort-fbm-sku")
              element.classList.add("text-warning")
            }}
          >
            FBM SKU{" "}
            <span>
              {restockSheetSort.fbmsku > 0 ? (
                restockSheetSort.fbmsku % 2 === 0 ? (
                  <i className="mdi mdi-arrow-up-drop-circle text-secondary"></i>
                ) : (
                  <i className="mdi mdi-arrow-down-drop-circle text-secondary"></i>
                )
              ) : null}
            </span>
          </div>
          <div>ASIN</div>
          <div
            className="text-primary pointer col-head-color"
            id="custom-sort-product-name"
            onMouseOver={() => {
              let element = document.getElementById("custom-sort-product-name")
              element.classList.add("text-warning")
            }}
            onMouseOut={() => {
              let element = document.getElementById("custom-sort-product-name")
              element.classList.add("text-primary")
              element.classList.remove("text-warning")
            }}
            onClick={() => {
              customSortByProductName()
              let element = document.getElementById("custom-sort-product-name")
              element.classList.add("text-warning")
            }}
          >
            Product Name{" "}
            <span>
              {restockSheetSort.productName > 0 ? (
                restockSheetSort.productName % 2 === 0 ? (
                  <i className="mdi mdi-arrow-up-drop-circle text-secondary"></i>
                ) : (
                  <i className="mdi mdi-arrow-down-drop-circle text-secondary"></i>
                )
              ) : null}
            </span>
          </div>
          <span
            className="info-icon"
            data-toggle="tooltip"
            data-tooltip-id="my-tooltip"
            data-tooltip-html="FBM SKU - Fulfilled by Merchant Stock Keeping Unit<br /><br />
            Items for which you have created a SKU and will send out of your warehouse to the customer. Clicking takes you to Amazon Seller Central FBM Detail Page
            <br /><br />
            ASIN – Amazon Standard Identification Number – Click to go to Amazon Product Detail Page in Seller Central
            <br /><br />
            Product Name – Click to go to the Inventory Status page inside Active Inventory.  Here you can add expiration date, tags, and tax codes.  
            <br /><br />
            "
          >
            <i className="bx bx-info-circle text-primary font-weight-bold h5"></i>
          </span>
        </div>
      ),
      selector: row => row.fbaSku,
      cell: row => format_FAP(row),
      grow: 1,
    },
    {
      name: (
        <div className="d-flex flex-column">
          <div
            className="text-primary pointer col-head-color"
            id="custom-sort-fba-sku"
            onMouseOver={() => {
              let element = document.getElementById("custom-sort-fba-sku")
              element.classList.add("text-warning")
            }}
            onMouseOut={() => {
              let element = document.getElementById("custom-sort-fba-sku")
              element.classList.add("text-primary")
              element.classList.remove("text-warning")
            }}
            onClick={() => {
              customSortByFbaSku()
              let element = document.getElementById("custom-sort-fba-sku")
              element.classList.add("text-warning")
            }}
          >
            FBA SKU{" "}
            <span>
              {restockSheetSort.fbasku > 0 ? (
                restockSheetSort.fbasku % 2 === 0 ? (
                  <i className="mdi mdi-arrow-up-drop-circle text-secondary"></i>
                ) : (
                  <i className="mdi mdi-arrow-down-drop-circle text-secondary"></i>
                )
              ) : null}
            </span>
          </div>
          <div>Product Image</div>

          <span
            className="info-icon"
            data-toggle="tooltip"
            data-tooltip-id="my-tooltip"
            data-tooltip-html="FBA SKU – Fulfilled by Amazon Stock Keeping Unit - User created and will manage the physical inventory in the Amazon Warehouse System.  Click to go to the FBA detail page for this product in Seller Central."
          >
            <i className="bx bx-info-circle text-primary font-weight-bold h5"></i>
          </span>
        </div>
      ),
      selector: row => row.fbmSku,
      cell: row => format_FPI(row),
      grow: 1,
      wrap: true,
      width: "156px",
    },
    {
      name: (
        <div className="d-flex flex-column">
          <div
            className="text-primary pointer col-head-color"
            id="custom-sort-category"
            onMouseOver={() => {
              let element = document.getElementById("custom-sort-category")
              element.classList.add("text-warning")
            }}
            onMouseOut={() => {
              let element = document.getElementById("custom-sort-category")
              element.classList.add("text-primary")
              element.classList.remove("text-warning")
            }}
            onClick={() => {
              customSortByCategory()
              let element = document.getElementById("custom-sort-category")
              element.classList.add("text-warning")
            }}
          >
            Category{" "}
            <span>
              {restockSheetSort.category > 0 ? (
                restockSheetSort.category % 2 === 0 ? (
                  <i className="mdi mdi-arrow-up-drop-circle text-secondary"></i>
                ) : (
                  <i className="mdi mdi-arrow-down-drop-circle text-secondary"></i>
                )
              ) : null}
            </span>
          </div>
          <div
            className="text-primary pointer col-head-color"
            id="custom-sort-rank"
            onMouseOver={() => {
              let element = document.getElementById("custom-sort-rank")
              element.classList.add("text-warning")
            }}
            onMouseOut={() => {
              let element = document.getElementById("custom-sort-rank")
              element.classList.add("text-primary")
              element.classList.remove("text-warning")
            }}
            onClick={() => {
              customSortByRank()
              let element = document.getElementById("custom-sort-rank")
              element.classList.add("text-warning")
            }}
          >
            Rank{" "}
            <span>
              {restockSheetSort.rank > 0 ? (
                restockSheetSort.rank % 2 === 0 ? (
                  <i className="mdi mdi-arrow-up-drop-circle text-secondary"></i>
                ) : (
                  <i className="mdi mdi-arrow-down-drop-circle text-secondary"></i>
                )
              ) : null}
            </span>
          </div>
          <div>Tags</div>
          <span
            className="info-icon"
            data-toggle="tooltip"
            data-tooltip-id="my-tooltip"
            data-tooltip-html="Rank – How often an item is selling on Amazon - the lower the number the faster it sells.</br></br>
            Tags – Use inside Active Inventory to keep track of groups of products. Enter information on the Inventory Status Page
            "
          >
            <i className="bx bx-info-circle text-primary font-weight-bold h5"></i>
          </span>
        </div>
      ),
      selector: row => row.category,
      cell: row => format_CR(row),
      grow: 1,
      width: "130px",
    },
    {
      name: (
        <div style={{ textAlign: "left" }}>
          <ins>FBA</ins>
          <br />
          <span>Current Price</span>
          <br />
          <span>Total Fees</span>
          <br />
          <ins>FBM</ins>
          <br />
          <span>Current Price</span>
          <br />
          <span>Total Fees</span>
          <br />
          Buy Box
          <span
            className="info-icon"
            data-toggle="tooltip"
            data-tooltip-id="my-tooltip"
            data-tooltip-html="Current Price – Your set price on Amazon</br></br>
            FBA Fees – The cost to use FBA (includes referral fee and pick & pack fee) </br></br>
            FBM Fees – This is the 15% referral fee – does not include shipping cost from your warehouse to the customer</br></br>
            Buy Box – Current Amazon Buy Box Price
            "
          >
            <i className="bx bx-info-circle text-primary font-weight-bold h5"></i>
          </span>
        </div>
      ),
      cell: row => format_FBCT(row, buyboxprice),
      grow: 0,
    },
    {
      name: (
        <>
          <Row>
            <div>
              <ins>
                Orders FBM{" "}
                <span>
                  {sortByDays.fbm > 0 ? (
                    sortByDays.fbm % 2 === 0 ? (
                      <i className="mdi mdi-arrow-up-drop-circle text-secondary"></i>
                    ) : (
                      <i className="mdi mdi-arrow-down-drop-circle text-secondary"></i>
                    )
                  ) : null}
                </span>
              </ins>
            </div>
            <Row className="d-flex flex-col">
              <Col className="col-5 table-title-col">
                <div
                  className="text-primary pointer col-head-color text-light"
                  id="custom-sort-fbm-3d"
                  onMouseOver={() => {
                    let element = document.getElementById("custom-sort-fbm-3d")
                    element.classList.add("text-warning")
                  }}
                  onMouseOut={() => {
                    let element = document.getElementById("custom-sort-fbm-3d")
                    element.classList.add("text-primary")
                    element.classList.remove("text-warning")
                  }}
                  onClick={() => {
                    customSortByDays("fbm3DaySales")
                    let element = document.getElementById("custom-sort-fbm-3d")
                    element.classList.add("text-warning")
                  }}
                >
                  3D
                </div>
                <div
                  className="text-primary pointer col-head-color text-light"
                  id="custom-sort-fbm-7d"
                  onMouseOver={() => {
                    let element = document.getElementById("custom-sort-fbm-7d")
                    element.classList.add("text-warning")
                  }}
                  onMouseOut={() => {
                    let element = document.getElementById("custom-sort-fbm-7d")
                    element.classList.add("text-primary")
                    element.classList.remove("text-warning")
                  }}
                  onClick={() => {
                    customSortByDays("fbm7DaySales")
                    let element = document.getElementById("custom-sort-fbm-7d")
                    element.classList.add("text-warning")
                  }}
                >
                  7D
                </div>
                <div
                  className="text-primary pointer col-head-color text-light"
                  id="custom-sort-fbm-14d"
                  onMouseOver={() => {
                    let element = document.getElementById("custom-sort-fbm-14d")
                    element.classList.add("text-warning")
                  }}
                  onMouseOut={() => {
                    let element = document.getElementById("custom-sort-fbm-14d")
                    element.classList.add("text-primary")
                    element.classList.remove("text-warning")
                  }}
                  onClick={() => {
                    customSortByDays("fbm14DaySales")
                    let element = document.getElementById("custom-sort-fbm-14d")
                    element.classList.add("text-warning")
                  }}
                >
                  14D
                </div>
              </Col>
              <Col className="col-7 table-title-col pl-4">
                <div
                  className="text-primary pointer col-head-color text-light"
                  id="custom-sort-fbm-30d"
                  onMouseOver={() => {
                    let element = document.getElementById("custom-sort-fbm-30d")
                    element.classList.add("text-warning")
                  }}
                  onMouseOut={() => {
                    let element = document.getElementById("custom-sort-fbm-30d")
                    element.classList.add("text-primary")
                    element.classList.remove("text-warning")
                  }}
                  onClick={() => {
                    customSortByDays("fbm30DaySales")
                    let element = document.getElementById("custom-sort-fbm-30d")
                    element.classList.add("text-warning")
                  }}
                >
                  30D
                </div>
                <div
                  className="text-primary pointer col-head-color text-light"
                  id="custom-sort-fbm-60d"
                  onMouseOver={() => {
                    let element = document.getElementById("custom-sort-fbm-60d")
                    element.classList.add("text-warning")
                  }}
                  onMouseOut={() => {
                    let element = document.getElementById("custom-sort-fbm-60d")
                    element.classList.add("text-primary")
                    element.classList.remove("text-warning")
                  }}
                  onClick={() => {
                    customSortByDays("fbm60DaySales")
                    let element = document.getElementById("custom-sort-fbm-60d")
                    element.classList.add("text-warning")
                  }}
                >
                  60D
                </div>
                <div
                  className="text-primary pointer col-head-color text-light"
                  id="custom-sort-fbm-90d"
                  onMouseOver={() => {
                    let element = document.getElementById("custom-sort-fbm-90d")
                    element.classList.add("text-warning")
                  }}
                  onMouseOut={() => {
                    let element = document.getElementById("custom-sort-fbm-90d")
                    element.classList.add("text-primary")
                    element.classList.remove("text-warning")
                  }}
                  onClick={() => {
                    customSortByDays("fbm90DaySales")
                    let element = document.getElementById("custom-sort-fbm-90d")
                    element.classList.add("text-warning")
                  }}
                >
                  90D
                </div>
              </Col>
            </Row>
          </Row>
          <span
            className="info-icon"
            data-toggle="tooltip"
            data-tooltip-id="my-tooltip"
            data-tooltip-html="Number of FBM orders (from your warehouse)"
          >
            <i className="bx bx-info-circle text-primary font-weight-bold h5"></i>
          </span>
        </>
      ),
      cell: row => format_OFBM(row),
      grow: 4,
      width: "150px",
    },
    {
      name: (
        <>
          <Row>
            <div>
              <ins>
                Orders FBA
                {sortByDays.fba > 0 ? (
                  sortByDays.fba % 2 === 0 ? (
                    <i className="mdi mdi-arrow-up-drop-circle text-secondary"></i>
                  ) : (
                    <i className="mdi mdi-arrow-down-drop-circle text-secondary"></i>
                  )
                ) : null}
              </ins>
            </div>
            <Row className="d-flex flex-col">
              <Col className="col-6 table-title-col">
                <div
                  className="text-primary pointer col-head-color text-light"
                  id="custom-sort-fba-3d"
                  onMouseOver={() => {
                    let element = document.getElementById("custom-sort-fba-3d")
                    element.classList.add("text-warning")
                  }}
                  onMouseOut={() => {
                    let element = document.getElementById("custom-sort-fba-3d")
                    element.classList.add("text-primary")
                    element.classList.remove("text-warning")
                  }}
                  onClick={() => {
                    customSortByDays("fba3DaySales")
                    let element = document.getElementById("custom-sort-fba-3d")
                    element.classList.add("text-warning")
                  }}
                >
                  3D
                </div>
                <div
                  className="text-primary pointer col-head-color text-light"
                  id="custom-sort-fba-7d"
                  onMouseOver={() => {
                    let element = document.getElementById("custom-sort-fba-7d")
                    element.classList.add("text-warning")
                  }}
                  onMouseOut={() => {
                    let element = document.getElementById("custom-sort-fba-7d")
                    element.classList.add("text-primary")
                    element.classList.remove("text-warning")
                  }}
                  onClick={() => {
                    customSortByDays("fba7DaySales")
                    let element = document.getElementById("custom-sort-fba-7d")
                    element.classList.add("text-warning")
                  }}
                >
                  7D
                </div>
                <div
                  className="text-primary pointer col-head-color text-light"
                  id="custom-sort-fba-14d"
                  onMouseOver={() => {
                    let element = document.getElementById("custom-sort-fba-14d")
                    element.classList.add("text-warning")
                  }}
                  onMouseOut={() => {
                    let element = document.getElementById("custom-sort-fba-14d")
                    element.classList.add("text-primary")
                    element.classList.remove("text-warning")
                  }}
                  onClick={() => {
                    customSortByDays("fba14DaySales")
                    let element = document.getElementById("custom-sort-fba-14d")
                    element.classList.add("text-warning")
                  }}
                >
                  14D
                </div>
              </Col>
              <Col className="col-6 table-title-col pl-4">
                <div
                  className="text-primary pointer col-head-color text-light"
                  id="custom-sort-fba-30d"
                  onMouseOver={() => {
                    let element = document.getElementById("custom-sort-fba-30d")
                    element.classList.add("text-warning")
                  }}
                  onMouseOut={() => {
                    let element = document.getElementById("custom-sort-fba-30d")
                    element.classList.add("text-primary")
                    element.classList.remove("text-warning")
                  }}
                  onClick={() => {
                    customSortByDays("fba30DaySales")
                    let element = document.getElementById("custom-sort-fba-30d")
                    element.classList.add("text-warning")
                  }}
                >
                  30D
                </div>
                <div
                  className="text-primary pointer col-head-color text-light"
                  id="custom-sort-fba-60d"
                  onMouseOver={() => {
                    let element = document.getElementById("custom-sort-fba-60d")
                    element.classList.add("text-warning")
                  }}
                  onMouseOut={() => {
                    let element = document.getElementById("custom-sort-fba-60d")
                    element.classList.add("text-primary")
                    element.classList.remove("text-warning")
                  }}
                  onClick={() => {
                    customSortByDays("fba60DaySales")
                    let element = document.getElementById("custom-sort-fba-60d")
                    element.classList.add("text-warning")
                  }}
                >
                  60D
                </div>
                <div
                  className="text-primary pointer col-head-color text-light"
                  id="custom-sort-fba-90d"
                  onMouseOver={() => {
                    let element = document.getElementById("custom-sort-fba-90d")
                    element.classList.add("text-warning")
                  }}
                  onMouseOut={() => {
                    let element = document.getElementById("custom-sort-fba-90d")
                    element.classList.add("text-primary")
                    element.classList.remove("text-warning")
                  }}
                  onClick={() => {
                    customSortByDays("fba90DaySales")
                    let element = document.getElementById("custom-sort-fba-90d")
                    element.classList.add("text-warning")
                  }}
                >
                  90D
                </div>
              </Col>
            </Row>
            <div
              className="text-primary pointer col-head-color"
              id="custom-sort-str"
              onMouseOver={() => {
                let element = document.getElementById("custom-sort-str")
                element.classList.add("text-warning")
              }}
              onMouseOut={() => {
                let element = document.getElementById("custom-sort-str")
                element.classList.add("text-primary")
                element.classList.remove("text-warning")
              }}
              onClick={() => {
                customSortBySTR()
                let element = document.getElementById("custom-sort-str")
                element.classList.add("text-warning")
              }}
            >
              Sell Through Rate
              <span>
                {restockSheetSort.str > 0 ? (
                  restockSheetSort.str % 2 === 0 ? (
                    <i className="mdi mdi-arrow-up-drop-circle text-secondary"></i>
                  ) : (
                    <i className="mdi mdi-arrow-down-drop-circle text-secondary"></i>
                  )
                ) : null}
              </span>
            </div>
          </Row>
          <span
            className="info-icon"
            data-toggle="tooltip"
            data-tooltip-id="my-tooltip" data-tooltip-html="Number of FBA Orders </br></br>
            Sell Though Rate – Calculation of stock turn over in Amazon Warehouse System – above 2 is a good rate.">
            <i className="bx bx-info-circle text-primary font-weight-bold h5"></i>
          </span>
        </>
      ),
      selector: row => row.str,
      cell: row => format_OFBA(row),
      grow: 4,
      width: "150px",
    },
    {
      cell: row => format_ATSI(row),
      name: (
        <div className="d-flex flex-column">
          <div
            className="text-primary pointer col-head-color"
            id="custom-sort-available"
            onMouseOver={() => {
              let element = document.getElementById("custom-sort-available")
              element.classList.add("text-warning")
            }}
            onMouseOut={() => {
              let element = document.getElementById("custom-sort-available")
              element.classList.add("text-primary")
              element.classList.remove("text-warning")
            }}
            onClick={() => {
              customSortByAvailable()
              let element = document.getElementById("custom-sort-available")
              element.classList.add("text-warning")
            }}
          >
            Available{" "}
            <span>
              {restockSheetSort.available > 0 ? (
                restockSheetSort.available % 2 === 0 ? (
                  <i className="mdi mdi-arrow-up-drop-circle text-secondary"></i>
                ) : (
                  <i className="mdi mdi-arrow-down-drop-circle text-secondary"></i>
                )
              ) : null}
            </span>
          </div>
          <div>Transfer</div>
          <div
            className="text-primary pointer col-head-color"
            id="custom-sort-inbound"
            onMouseOver={() => {
              let element = document.getElementById("custom-sort-inbound")
              element.classList.add("text-warning")
            }}
            onMouseOut={() => {
              let element = document.getElementById("custom-sort-inbound")
              element.classList.add("text-primary")
              element.classList.remove("text-warning")
            }}
            onClick={() => {
              customSortByInbound()
              let element = document.getElementById("custom-sort-inbound")
              element.classList.add("text-warning")
            }}
          >
            Inbound{" "}
            <span>
              {restockSheetSort.inbound > 0 ? (
                restockSheetSort.inbound % 2 === 0 ? (
                  <i className="mdi mdi-arrow-up-drop-circle text-secondary"></i>
                ) : (
                  <i className="mdi mdi-arrow-down-drop-circle text-secondary"></i>
                )
              ) : null}
            </span>
          </div>
          <div
            className="text-primary pointer col-head-color"
            id="custom-sort-total"
            onMouseOver={() => {
              let element = document.getElementById("custom-sort-total")
              element.classList.add("text-warning")
            }}
            onMouseOut={() => {
              let element = document.getElementById("custom-sort-total")
              element.classList.add("text-primary")
              element.classList.remove("text-warning")
            }}
            onClick={() => {
              customSortByTotal()
              let element = document.getElementById("custom-sort-total")
              element.classList.add("text-warning")
            }}
          >
            Total{" "}
            <span>
              {restockSheetSort.total > 0 ? (
                restockSheetSort.total % 2 === 0 ? (
                  <i className="mdi mdi-arrow-up-drop-circle text-secondary"></i>
                ) : (
                  <i className="mdi mdi-arrow-down-drop-circle text-secondary"></i>
                )
              ) : null}
            </span>
          </div>
          <span
            className="info-icon"
            data-toggle="tooltip"
            data-tooltip-id="my-tooltip"
            data-tooltip-html="Available – FBA Amount that is ready for purchase</br></br>
            Transfer – Moving in-between Amazon Warehouses</br></br>
            Inbound – Enroute to Amazon Warehouses </br></br>
            Total – Available + Transfer + Inbound</br></br>"
          >
            <i className="bx bx-info-circle text-primary font-weight-bold h5"></i>
          </span>
        </div>
      ),
      selector: row => row.totalQty,
      grow: 0,
      width: "75px",
    },
    {
      cell: row => format_RFUO(row),
      name: (
        <div className="d-flex flex-column">
          <div>Orders</div>
          <div>FC Processing</div>
          <div>Unfulfillable</div>
          <div>Oversized</div>

          <span
            className="info-icon"
            data-toggle="tooltip"
            data-tooltip-id="my-tooltip"
            data-tooltip-html="Orders – Items being processed for customer orders</br></br>
            FC Processing – Products set aside for different checks </br></br>
            Unfulfillable – Unsellable </br></br>
            Oversized – If yes, product has met Amazon’s criteria to be in the “Oversized” tier."
          >
            <i className="bx bx-info-circle text-primary font-weight-bold h5"></i>
          </span>
        </div>
      ),
      selector: row => row.reservedQty,
      grow: 1,
      width: "108px",
    },
    {
      cell: row => format_DDD(row),
      name: (
        <div className="d-flex flex-column">
          <div
            className="text-primary pointer col-head-color"
            id="custom-sort-daysRemaining"
            onMouseOver={() => {
              let element = document.getElementById("custom-sort-daysRemaining")
              element.classList.add("text-warning")
            }}
            onMouseOut={() => {
              let element = document.getElementById("custom-sort-daysRemaining")
              element.classList.add("text-primary")
              element.classList.remove("text-warning")
            }}
            onClick={() => {
              customSortByDaysRemaining()
              let element = document.getElementById("custom-sort-daysRemaining")
              element.classList.add("text-warning")
            }}
          >
            Days Remaining{" "}
            <span>
              {restockSheetSort.daysRemaining > 0 ? (
                restockSheetSort.daysRemaining % 2 === 0 ? (
                  <i className="mdi mdi-arrow-up-drop-circle text-secondary"></i>
                ) : (
                  <i className="mdi mdi-arrow-down-drop-circle text-secondary"></i>
                )
              ) : null}
            </span>
          </div>
          <div
            className="text-primary pointer col-head-color"
            id="custom-sort-daysOOS"
            onMouseOver={() => {
              let element = document.getElementById("custom-sort-daysOOS")
              element.classList.add("text-warning")
            }}
            onMouseOut={() => {
              let element = document.getElementById("custom-sort-daysOOS")
              element.classList.add("text-primary")
              element.classList.remove("text-warning")
            }}
            onClick={() => {
              customSortByDaysOOS()
              let element = document.getElementById("custom-sort-daysOOS")
              element.classList.add("text-warning")
            }}
          >
            Days Out of Stock{" "}
            <span>
              {restockSheetSort.daysOOS > 0 ? (
                restockSheetSort.daysOOS % 2 === 0 ? (
                  <i className="mdi mdi-arrow-up-drop-circle text-secondary"></i>
                ) : (
                  <i className="mdi mdi-arrow-down-drop-circle text-secondary"></i>
                )
              ) : null}
            </span>
          </div>
          <div
            className="text-primary pointer col-head-color"
            id="custom-sort-wps"
            onMouseOver={() => {
              let element = document.getElementById("custom-sort-wps")
              element.classList.add("text-warning")
            }}
            onMouseOut={() => {
              let element = document.getElementById("custom-sort-wps")
              element.classList.add("text-primary")
              element.classList.remove("text-warning")
            }}
            onClick={() => {
              customSortByWPS()
              let element = document.getElementById("custom-sort-wps")
              element.classList.add("text-warning")
            }}
          >
            Weekly Projected Sales{" "}
            <span>
              {restockSheetSort.wps > 0 ? (
                restockSheetSort.wps % 2 === 0 ? (
                  <i className="mdi mdi-arrow-up-drop-circle text-secondary"></i>
                ) : (
                  <i className="mdi mdi-arrow-down-drop-circle text-secondary"></i>
                )
              ) : null}
            </span>
          </div>
          <span
            className="info-icon"
            data-toggle="tooltip"
            data-tooltip-id="my-tooltip"
            data-tooltip-html="Days Remaining – How many available days of FBA stock Days </br></br>
            Out of Stock – In the last 90 days, the number of days with 0 stock</br></br>
            Weekly Projected Sales – Average sales in 7 days"
          >
            <i className="bx bx-info-circle text-primary font-weight-bold h5"></i>
          </span>
        </div>
      ),
      selector: row => row.daysRemaining,
      grow: 0,
      width: "90px",
    },
    {
      cell: row => format_RA(row),
      name: (
        <Row className="d-flex flex-column">
          <div>
            <ins>Restock History</ins>
          </div>
          <div>Date:amount</div>
          <div>Sent/Received</div>
          <span
            className="info-icon"
            data-toggle="tooltip"
            data-tooltip-id="my-tooltip"
            data-tooltip-html="Date/Amount – Last four amounts sent into FBA based on Finalized Restock Sheets</br></br>
            Sent/Received – Sent is how many items are in transit. Received is how many items are at the warehouse but not yet available."
          >
            <i className="bx bx-info-circle text-primary font-weight-bold h5"></i>
          </span>
        </Row>
      ),
      grow: 3,
      width: "110px",
    },
    {
      cell: (row, index) =>
        format_UTM(row, index, removeExpiryDate, handleExpiryDateSelect),
      name: (
        <div className="d-flex flex-column">
          <div>
            <ins>Suggested # Units</ins>
            <ins> to go to FBA</ins>
          </div>
          <div className="d-flex justify-content-center">
            <div
              className="text-primary pointer col-head-color"
              id="custom-sort-min"
              onMouseOver={() => {
                let element = document.getElementById("custom-sort-min")
                element.classList.add("text-warning")
              }}
              onMouseOut={() => {
                let element = document.getElementById("custom-sort-min")
                element.classList.add("text-primary")
                element.classList.remove("text-warning")
              }}
              onClick={() => {
                customSortByMin()
                let element = document.getElementById("custom-sort-min")
                element.classList.add("text-warning")
              }}
            >
              Min{" "}
              <span>
                {restockSheetSort.min > 0 ? (
                  restockSheetSort.min % 2 === 0 ? (
                    <i className="mdi mdi-arrow-up-drop-circle text-secondary"></i>
                  ) : (
                    <i className="mdi mdi-arrow-down-drop-circle text-secondary"></i>
                  )
                ) : null}
              </span>
            </div>
            <div className="text-primary">/</div>
            <div
              className="text-primary pointer col-head-color"
              id="custom-sort-max"
              onMouseOver={() => {
                let element = document.getElementById("custom-sort-max")
                element.classList.add("text-warning")
              }}
              onMouseOut={() => {
                let element = document.getElementById("custom-sort-max")
                element.classList.add("text-primary")
                element.classList.remove("text-warning")
              }}
              onClick={() => {
                customSortByMax()
                let element = document.getElementById("custom-sort-max")
                element.classList.add("text-warning")
              }}
            >
              Max
              <span>
                {restockSheetSort.max > 0 ? (
                  restockSheetSort.max % 2 === 0 ? (
                    <i className="mdi mdi-arrow-up-drop-circle text-secondary"></i>
                  ) : (
                    <i className="mdi mdi-arrow-down-drop-circle text-secondary"></i>
                  )
                ) : null}
              </span>
            </div>
          </div>
          <div>(Target Days)</div>
          <div
            className="text-primary pointer col-head-color"
            id="custom-sort-expiry-date"
            onMouseOver={() => {
              let element = document.getElementById("custom-sort-expiry-date")
              element.classList.add("text-warning")
            }}
            onMouseOut={() => {
              let element = document.getElementById("custom-sort-expiry-date")
              element.classList.add("text-primary")
              element.classList.remove("text-warning")
            }}
            onClick={() => {
              customExpiryDateSort()
              let element = document.getElementById("custom-sort-expiry-date")
              element.classList.add("text-warning")
            }}
          >
            Expiration Date{" "}
            <span>
              {restockSheetSort.expiryDate > 0 ? (
                restockSheetSort.expiryDate % 2 === 0 ? (
                  <i className="mdi mdi-arrow-up-drop-circle text-secondary"></i>
                ) : (
                  <i className="mdi mdi-arrow-down-drop-circle text-secondary"></i>
                )
              ) : null}
            </span>
          </div>
          <span
            className="info-icon"
            data-toggle="tooltip"
            data-tooltip-id="my-tooltip"
            data-tooltip-html="Suggested Units to FBA – Min/Max – Based on the range you have chosen for how many days you want to stay in stock at the Amazon Warehouses.</br></br>
            Expiration Date - For dated products – Add on the inventory Page "
          >
            <i className="bx bx-info-circle text-primary font-weight-bold h5"></i>
          </span>
        </div>
      ),
      selector: row => row.maxDays,
      grow: 0,
    },
    {
      selector: row => updatedValue.qty || row.qty,
      cell: (row, index) =>
        format_FSA(
          row,
          handleInput,
          updatedValue,
          handleAddProduct,
          handleDeleteProduct,
          index
        ),
      name: (
        <div
          className="text-primary pointer col-head-color"
          id="custom-sort-shipment-amount"
          onMouseOver={() => {
            let element = document.getElementById("custom-sort-shipment-amount")
            element.classList.add("text-warning")
          }}
          onMouseOut={() => {
            let element = document.getElementById("custom-sort-shipment-amount")
            element.classList.add("text-primary")
            element.classList.remove("text-warning")
          }}
          onClick={() => {
            customShipmentAmountSort()
            let element = document.getElementById("custom-sort-shipment-amount")
            element.classList.add("text-warning")
          }}
        >
          FBA
          <br />
          Shipment
          <br />
          Amount{" "}
          <span>
            {restockSheetSort.qty > 0 ? (
              restockSheetSort.qty % 2 === 0 ? (
                <i className="mdi mdi-arrow-up-drop-circle text-secondary"></i>
              ) : (
                <i className="mdi mdi-arrow-down-drop-circle text-secondary"></i>
              )
            ) : null}
          </span>
          <span
            className="info-icon"
            data-toggle="tooltip"
            data-tooltip-id="my-tooltip"
            data-tooltip-html="FBA Shipment Amount – The number of items you want to send on your next shipment to Amazon."
          >
            <i className="bx bx-info-circle text-primary font-weight-bold h5"></i>
          </span>
        </div>
      ),
      grow: 0,
      width: "90px",
    },
    {
      cell: (row, index) =>
        format_NOTES(
          row,
          handleNotesInput,
          updatedValue,
          handleAddProduct,
          index
        ),
      name: (
        <div>
          <div>Restock Notes</div>
          <span
            className="info-icon"
            data-toggle="tooltip"
            data-tooltip-id="my-tooltip"
            data-tooltip-html="Notes – Reminders for this product."
          >
            <i className="bx bx-info-circle text-primary font-weight-bold h5"></i>
          </span>
        </div>
      ),
      selector: row => updatedValue.notes || row.notes,
    },
  ]

  const handleExpiryDateSelect = (e, row) => {
    const { value } = e.target
    const [year, month, date] =
      value !== undefined ? value.split("-") : ["", "", ""]
    let expDate = value !== undefined ? `${month}-${date}-${year}` : ""
    let date_regex = /^(0[1-9]|1[0-2])\-(0[1-9]|1\d|2\d|3[01])\-(19|20)\d{2}$/
    if (e.key === "Enter" || e.keyCode === 13 || e.type === "blur") {
      if (!date_regex.test(expDate) && value !== undefined) {
        value !== "" &&
          addToast(
            "Please enter valid value. This field is incomplete or has and invalid date!",
            {
              appearance: "error",
              autoDismiss: true,
            }
          )
      } else {
        dispatch(
          setProductExpiryDate(
            { data: { id: row.id, expDate: expDate } },
            response => {
              addToast("Product Expiry Date updated successfully!", {
                appearance: "success",
                autoDismiss: true,
              })

              let tempUpdatedValue = { ...updatedValue }
              tempUpdatedValue[`${row.id}`] = {
                ...tempUpdatedValue[`${row.id}`],
                valueUpdated: false,
              }
              setUpdatedValue({ ...tempUpdatedValue })

              let tempData = [...restock]
              let filteredDataIndex = tempData.findIndex(
                item => item.id === row.id
              )
              let filteredData = tempData[filteredDataIndex]
              filteredData["expiryDate"] =
                value !== undefined ? `${month}/${date}/${year}` : ""
              restock.splice(filteredDataIndex, 1, filteredData)
              setRestockData([...restock])
              setSortedRestockSheetData([...restock])
            },
            errorData => {
              addToast(errorData.response.data.error.displayMessage, {
                appearance: "error",
                autoDismiss: true,
              })
            }
          )
        )
      }
    }
  }

  const removeExpiryDate = (e, row) => {
    const { value } = e.target
    const [year, month, date] =
      value !== undefined ? value.split("-") : ["", "", ""]
    let expDate = value !== undefined ? `${month} - ${date} - ${year}` : ""
    dispatch(
      setProductExpiryDate(
        { data: { id: row.id, expDate: "" } },
        response => {
          let tempUpdatedValue = { ...updatedValue }
          tempUpdatedValue[`${row.id}`] = {
            ...tempUpdatedValue[`${row.id}`],
            valueUpdated: false,
          }
          setUpdatedValue({ ...tempUpdatedValue })

          let tempData = [...restock]
          let filteredDataIndex = tempData.findIndex(item => item.id === row.id)
          let filteredData = tempData[filteredDataIndex]
          filteredData["expiryDate"] = ""
          restock.splice(filteredDataIndex, 1, filteredData)
          setRestockData([...restock])
          setSortedRestockSheetData([...restock])

          addToast(response.data, {
            appearance: "success",
            autoDismiss: true,
          })
        },
        errorData => {
          addToast(errorData.response.data.error.displayMessage, {
            appearance: "error",
            autoDismiss: true,
          })
        }
      )
    )
  }

  const changeStatus = row => {
    dispatch(
      statusProductTag(
        { productId: row.id, status: true },
        response => {
          if (response.data) {
            let tempSheetData = sortedRestockSheetData.length
              ? [...sortedRestockSheetData]
              : [...restockOriginal]
            let newSheetData = tempSheetData.filter(item => item.id !== row.id)
            addToast("Product Hide Successfully!", {
              appearance: "success",
              autoDismiss: true,
            })
            setRestockData(newSheetData)
            setRestockOriginalData(newSheetData)
            setSortedRestockSheetData(newSheetData)
          }
        },
        errorData => {
          addToast(errorData.response.data.error.displayMessage, {
            appearance: "error",
            autoDismiss: true,
          })
        }
      )
    )
  }

  const handleInput = (value, row) => {
    setRowId(row.id)
     let tempUpdatedValue = { ...updatedValue }
     if (value === 0 || value === "" || value === null) {
       //delete qty
       tempUpdatedValue[`${row.id}`] = {
         ...tempUpdatedValue[`${row.id}`],
         qty: 0,
         sku: row.fbaSku,
         asin: row.asin,
         valueUpdated: true,
       }
     } else {
       //add qty
       tempUpdatedValue[`${row.id}`] = {
         ...tempUpdatedValue[`${row.id}`],
         qty: value,
         sku: row.fbaSku,
         asin: row.asin,
         valueUpdated: true,
       }

     }
    setRestockData(prevData => {
      const newData = prevData.map(item => {
        if (item.id === row.id) {
          if (value === 0 || value === "" || value === null) {
            return { ...item, shipmentQty: 0 }
          } else {
            return { ...item, shipmentQty: value }
          }
        }
        return item
      })
      return newData
    })
     setUpdatedValue({ ...tempUpdatedValue })
  }

  const handleNotesInput = (value, row, e) => {
    setRowId(row.id)
    e.preventDefault()
    let tempUpdatedValue = { ...updatedValue }
    //adding notes
    tempUpdatedValue[`${row.id}`] = {
       ...tempUpdatedValue[`${row.id}`],
       notes: value,
       sku: row.fbaSku,
       asin: row.asin,
       valueUpdated: true,
    }
    setRestockData(prevData => {
      const newData = prevData.map(item => {
        if (item.id === row.id) {
          return { ...item, notes: value }
        }
        return item
      })
      return newData
    })
     setUpdatedValue({ ...tempUpdatedValue })
  }

  const handleDeleteProduct = (e, index, type, row) => {
    if (e.key === "Enter" || e.keyCode === 13 || e.type === "blur") {
      const { name } = e.target
      const [fieldName, fieldIndex] = name.split("-")
      let isValueUpdated = []
      let updatedValueArray = []
      Object.keys(updatedValue).filter(
        item =>
          row.id === item &&
          isValueUpdated.push(updatedValue[item].valueUpdated)
      )
      Object.keys(updatedValue).map(item => {
        return updatedValueArray.push({
          id: item,
          sku: updatedValue[item].sku,
        })
      })
      isValueUpdated[0] &&
        dispatch(
          deleteItemFromRestockSheet(
            {
              data: { id: location?.state?.id, sku: updatedValueArray[0].sku },
            },
            responseData => {
              setInvLimit({
                ivLimit: responseData.data.inventoryLimit,
                ovLimit: responseData.data.oversizedLimit,
                totSku: responseData.data.totalSku,
                totalQty: responseData.data.totalQty,
              })
              addToast("Product deleted successfully!", {
                appearance: "success",
                autoDismiss: true,
              })
              //make flag valueUpdated false
              if (type === "amount") {
                let tempUpdatedValue = { ...updatedValue }
                tempUpdatedValue[`${row.id}`] = {
                  ...tempUpdatedValue[`${row.id}`],
                  valueUpdated: false,
                }
                setUpdatedValue({ ...tempUpdatedValue })

                let tempData = [...restock]
                let filteredDataIndex = tempData.findIndex(
                  item => item.id === row.id
                )
                let filteredData = tempData[filteredDataIndex]
                filteredData["shipmentQty"] = e.target.value
                restock.splice(filteredDataIndex, 1, filteredData)
                setRestockData([...restock])
                setSortedRestockSheetData([...restock])
              }

              // Check if it's not the last input field
              if (parseInt(fieldIndex, 10) < rowsPerPage) {
                const nextSibling = document.querySelector(
                  `input[name=amount-${parseInt(fieldIndex, 10) + 1}]`
                )
                if (nextSibling !== null) {
                  nextSibling.focus()
                }
              }
            },
            errorData => {
              addToast(errorData.response.data.error.displayMessage, {
                appearance: "error",
                autoDismiss: true,
              })
            }
          )
        )
    }
  }

  const handleAddProduct = (e, index, type, row) => {
    if (e.key === "Enter" || e.keyCode === 13 || e.type === "blur") {
      const { name } = e.target
      const [fieldName, fieldIndex] = name.split("-")

      let isValueUpdated = []
      let updatedValueArray = []
      Object.keys(updatedValue).filter(
        item =>{
          if(row.id === item){
            isValueUpdated.push(updatedValue[item].valueUpdated);
            updatedValueArray.push({ 
              productId: item,
              ...updatedValue[item]
            })  
          }
        }
      )
      /*Object.keys(updatedValue).map(item => {
        return updatedValueArray.push({ 
          productId: item,
          ...updatedValue[item],
        })
      })*/
      isValueUpdated[0] &&
        dispatch(
          addItemToRestockSheet(
            { data: { item: updatedValueArray, id: location?.state?.id } },
            responseData => {
              setInvLimit({
                ivLimit: responseData.data.inventoryLimit,
                ovLimit: responseData.data.oversizedLimit,
                totSku: responseData.data.totalSku,
                totalQty: responseData.data.totalQty,
              })
              addToast("Product updated successfully!", {
                appearance: "success",
                autoDismiss: true,
              })

              //make flag valueUpdated false
              if (type === "amount") {
                let tempUpdatedValue = { ...updatedValue }
                tempUpdatedValue[`${row.id}`] = {
                  ...tempUpdatedValue[`${row.id}`],
                  valueUpdated: false,
                }
                setUpdatedValue({ ...tempUpdatedValue })

                let tempData = [...restock]
                let filteredDataIndex = tempData.findIndex(
                  item => item.id === row.id
                )
                let filteredData = tempData[filteredDataIndex]
                filteredData["shipmentQty"] = e.target.value
                restock.splice(filteredDataIndex, 1, filteredData)
                setRestockData([...restock])
                setSortedRestockSheetData([...restock])
              } else {
                let tempUpdatedValue = { ...updatedValue }
                tempUpdatedValue[`${row.id}`] = {
                  ...tempUpdatedValue[`${row.id}`],
                  valueUpdated: false,
                }
                setUpdatedValue({ ...tempUpdatedValue })
              }

              // Check if it's not the last input field
              if (parseInt(fieldIndex, 10) < rowsPerPage) {
                const nextSibling =
                  type === "amount"
                    ? document.querySelector(
                        `input[name=amount-${parseInt(fieldIndex, 10) + 1}]`
                      )
                    : document.querySelector(
                        `textarea[name=note-${parseInt(fieldIndex, 10) + 1}]`
                      )
                if (
                  null !== nextSibling &&
                  (e.key === "Enter" || e.keyCode === 13)
                ) {
                  nextSibling.focus()
                }
              }
            },
            errorData => {
              addToast(errorData.response.data.error.displayMessage, {
                appearance: "error",
                autoDismiss: true,
              })
            }
          )
        )
    }
  }

  const handleRestockSheetAction = action => {
    action === "DONE"
      ? setRestockSheetDoneClick(!restockSheetDoneClick)
      : setRestockSheetDeleteClick(!restockSheetDeleteClick)
  }

  const handleRestockSheetDone = () => {
    dispatch(
      doneRestockSheet(
        { restockSheetId: location?.state?.id, status: "DONE" },
        responseData => {
          addToast("Restock sheet marked as done", {
            appearance: "success",
            autoDismiss: true,
          })
          history.push("/restock-report-history")
        },
        errorData => {
          addToast(errorData.response.data.error.displayMessage, {
            appearance: "error",
            autoDismiss: true,
          })
        }
      )
    )
  }

  const handleRestockSheetDelete = () => {
    dispatch(
      deleteRestockSheet(
        { restockSheetId: location?.state?.id },
        responseData => {
          addToast("Restock sheet deleted", {
            appearance: "success",
            autoDismiss: true,
          })
          history.push("/restock-sheet")
        },
        errorData => {
          addToast(errorData.response.data.error.displayMessage, {
            appearance: "error",
            autoDismiss: true,
          })
        }
      )
    )
  }

  const handleRefreshSheet = () => {
    setFilterValue("")
    setUpdatedValue({})
    setSelected([])
    setRestockSheetSort({
      status: 0,
      expiryDate: 0,
      qty: 0,
      fbmsku: 0,
      fbasku: 0,
      productName: 0,
      category: 0,
      rank: 0,
      str: 0,
      available: 0,
      inbound: 0,
      total: 0,
      daysRemaining: 0,
      daysOOS: 0,
      wps: 0,
      min: 0,
      max: 0,
    })
    setSortByDays({ fbm: 0, fba: 0 })
    setSortedRestockSheetData([])
    getRestockSheetDataById()
    setResetPaginationToggle(true)
  }

  const restockSheetModalCancelClick = action => {
    if (action === "DELETE") {
      setRestockSheetDeleteClick(false)
    } else {
      setRestockSheetDoneClick(false)
    }
  }

  const handleClearFilter = () => {
    setFilterValue("")
  }

  const handleOnlyFBMFilter = e => {
    const { checked } = e.target
    dispatch(
      getOnlyFbmSku(
        { restockSheetId: location?.state?.id, onlyFBM: checked ? 1 : 0 },
        responseData => {
          setRestockData(responseData.data.sku)
          setRestockOriginalData(responseData.data.sku)
        },
        errorData => {
          addToast(errorData.response.data.error.displayMessage, {
            appearance: "error",
            autoDismiss: true,
          })
        }
      )
    )
  }

  const printClick = () => {
    dispatch(setStartLoader())
    let myHeaders = new Headers()

    myHeaders.append(
      "X-CLIENTMARKETPLACE-ID",
      localStorage.getItem("marketplaceId")
    )
    myHeaders.append(
      "Authorization",
      localStorage.getItem("token_type") +
        " " +
        localStorage.getItem("access_token")
    )
    myHeaders.append("Content-Type", "application/json")

    let requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    }

    fetch(BASE_URL + `/restock/print?id=${location?.state?.id}`, requestOptions)
      .then(resp => resp.blob())
      .then(blob => {
        dispatch(setStopLoader())
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement("a")
        a.style.display = "none"
        a.href = url
        a.download = "FBARestockSheet.pdf"
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
        addToast("File downloaded successfully", {
          appearance: "success",
          autoDismiss: true,
        })
      })
      .catch(error => {
        dispatch(setStopLoader())
        addToast("Something went wrong!", {
          appearance: "error",
          autoDismiss: true,
        })
      })
  }

  const handlePageChange = e => {
    setCurrentPage(e)
  }

  const handlePerRowsChange = (e) => {
    setRowsPerPage(e)
    setCurrentPage(0)
  }

  return (
    <React.Fragment>
      {(restockSheetDeleteClick || restockSheetDoneClick) && (
        <Modal
          isOpen={restockSheetDeleteClick || restockSheetDoneClick}
          size="lg"
          className="w-100 modal-wrapper"
          autoFocus={true}
          backdrop
          toggle={() => <></>}
          style={{
            border: 0,
            backgroundColor: "#082C4E",
            borderRadius: "35px",
          }}
        >
          <ModalBody
            style={{
              backgroundColor: "#082C4E",
              border: 0,
              borderRadius: "35px",
            }}
          >
            <Row className="d-flex justify-content-end w-100 m-0 text-center">
              <div
                className="ai-modal-close-btn"
                onClick={() =>
                  restockSheetModalCancelClick(
                    restockSheetDeleteClick ? "DELETE" : "DONE"
                  )
                }
              >
                <div style={{ fontSize: "18px" }}>&#10005;</div>
              </div>
            </Row>
            <Row className="d-flex justify-content-center w-100 m-0 text-center mb-3">
              <div
                className="text-white"
                style={{ font: "bold 30px novecento-sans" }}
              >
                {restockSheetDeleteClick
                  ? "DELETE RESTOCK SHEET"
                  : "FINALIZE RESTOCK SHEET"}
              </div>
            </Row>
            {restockSheetDeleteClick ? (
              <>
                <div className="text-danger font-weight-bold h4">
                  Attention!!!
                </div>
                <div className="h5 text-white">
                  {`You are about to delete the restock sheet. All the data of this restock sheet will be deleted. If you do not want to delete and erase this information, please cancel this action.`}
                </div>
              </>
            ) : (
              <>
                <div>
                  <p className="h5 text-white">{`You are about to finalize this restock sheet. This action means you have completed this restock sheet. If you are not done with this sheet, please click on the "Cancel" button and continue editing.  If you are done with this sheet, please hit Finalize. After your Finalize a sheet you will be able to edit quantities of items but will not be able to add any new SKUs.`}</p>
                </div>
              </>
            )}
            <div className="d-flex justify-content-center col-12">
              <Row className="align-items-center">
                <Col className="d-flex justify-content-center">
                  <Button
                    className={
                      restockSheetDeleteClick
                        ? "ai-action-btn-danger btn btn-primary text-white"
                        : "ai-action-btn btn btn-primary"
                    }
                    color=""
                    onClick={() =>
                      restockSheetDeleteClick
                        ? handleRestockSheetDelete()
                        : handleRestockSheetDone()
                    }
                  >
                    {restockSheetDeleteClick ? "DELETE" : "FINALIZE"}
                  </Button>
                  <Button
                    className="ai-action-btn btn btn-primary"
                    color=""
                    onClick={() =>
                      restockSheetModalCancelClick(
                        restockSheetDeleteClick ? "DELETE" : "DONE"
                      )
                    }
                  >
                    CANCEL
                  </Button>
                </Col>
              </Row>
            </div>
          </ModalBody>
        </Modal>
      )}
      <div className="d-flex row align-items-center justify-content-between w-100 p-0 m-0">
        <div className="col-12 col-lg-4 alignment">
          <div className="d-flex justify-content-left align-items-center alignment">
            <p
              onClick={() => history.push("/restock-sheet")}
              className="pointer mb-2"
            >
              <i className="fas fa-angle-left"></i> All Restock Sheet
            </p>
          </div>
        </div>
        <div className="d-flex justify-content-left align-items-center alignment col-12">
          <div className="col-6 p-0">
            <div className="d-sm-flex align-items-center justify-content-left">
              <h4
                className="m-0"
                style={{
                  textAlign: "center",
                  font: "normal normal bold 20px novecento-sans",
                  letterSpacing: "0.32px",
                  color: "#082C4E",
                  textTransform: "uppercase",
                }}
              >
                {location?.state?.data?.name} |{" "}
                {moment(location?.state?.data?.date).format("YYYY-MM-DD")}
              </h4>
            </div>
          </div>
          <div className="col-6 d-flex justify-content-end align-items-center alignment p-0">
            <p className="mb-2">
              <strong>Total Items:&nbsp;</strong> {invLimit.totalQty}
              &nbsp;|&nbsp;
              <strong>Total SKUS:&nbsp;</strong> {invLimit.totSku}{" "}
            </p>
          </div>
        </div>
      </div>
      <div className="shadow-sm col-12 d-flex py-2 row table-filter text-white mx-1 p-0 ai-header-box-radius mb-1">
        <div className="d-flex col-lg-10 align-items-center sm-spacing pr-0">
          <div className="d-flex w-100">
            <div className="d-flex align-items-center sm-spacing text-white px-1">
              <a
                className="ai-action-btn btn btn-primary"
                color=""
                href="https://support.myactiveinventory.com/support/solutions/articles/150000011774-quick-start"
                target="_new"
              >
                QUICK START
              </a>
            </div>
            <div className="d-flex align-items-center sm-spacing text-white px-1 w-15">
              Filter&nbsp;By&nbsp;&nbsp;
              <MultiSelect
                className="my-2 text-dark multiselect-z-index multiselect-padding w-75"
                disableSearch
                shouldToggleOnHover
                options={options}
                value={selected}
                onChange={setSelected}
                labelledBy="Select"
                overrideStrings={{
                  selectSomeItems: "Status",
                }}
              />
            </div>
            <div className="d-flex align-items-center sm-spacing text-white px-1">
              Search&nbsp;By&nbsp;&nbsp;
              <InputGroup size="sm" className="d-flex align-items-center">
                <input
                  placeholder="Tag, Name, SKU, ASIN"
                  type="search"
                  className={
                    "border-1 w-100 border border-light ai-input-radius"
                  }
                  value={filterValue}
                  onChange={e => setFilterValue(e.target.value)}
                />
              </InputGroup>
            </div>
            <div className="d-flex align-items-center sm-spacing px-1">
              <input type="checkbox" onClick={e => handleOnlyFBMFilter(e)} />
              <label className="mb-0 ml-1">Show only FBM products</label>
            </div>
          </div>
        </div>
        <div className="d-flex col-lg-2 align-items-center justify-content-end sm-spacing pl-0">
          <div className="d-flex">
            <div
              className="text-white mr-2 pointer"
              onClick={() => handleRefreshSheet()}
            >
              <img
                src={RefreshIcon}
                title={"Refresh Restock Sheet"}
                className="restock-sheet-img"
              />
            </div>
            <div
              className="text-white mr-3 pointer"
              onClick={() => printClick()}
            >
              <img
                src={PrintIcon}
                title={"Print Draft"}
                className="restock-sheet-img"
              />
            </div>
            <div
              className="text-white mr-3 pointer"
              onClick={() => handleRestockSheetAction("DONE")}
            >
              <img
                src={FinalizeIcon}
                title={"Finalize sheet"}
                className="restock-sheet-img"
              />
            </div>
            <div
              className="text-white mr-3 pointer"
              onClick={() => handleRestockSheetAction("DELETE")}
            >
              <img
                src={DeleteIcon}
                title={"Delete Restock Sheet"}
                className="restock-sheet-img"
              />
            </div>
          </div>
        </div>
      </div>
      {/* <div className="ai-restock-tip">PRO-TIP: IF YOU CAN’T SEE ALL YOUR DATA ON 1 SCREEN, TRY ZOOMING OUT YOUR VIEW TO 75%</div> */}
      <div className="ai-Table">
        <DataTable
          columns={columns}
          data={restock}
          pagination
          paginationComponent={() => (
            <Pagination
              data={restock}
              rowsPerPage={rowsPerPage}
              currentPage={currentPage}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={(e) => handlePerRowsChange(e)}
              paginationRowsPerPageOptions={[5, 50, 100, 200, 300, 500]}
              rowCount={
                selected.length > 0 || filterValue?.length > 0
                  ? restock.length
                  : restockOriginal.length
              }
            />
          )}
          paginationPerPage={500}
          paginationResetDefaultPage={resetPaginationToggle}
          className="restock-history-data-table"
          persistTableHead
          fixedHeader
          responsive
          fixedHeaderScrollHeight="157vh"
          dense
        />
      </div>
    </React.Fragment>
  )
}

export default Restock
