/* eslint-disable react/react-in-jsx-scope */
import { Row, Col } from "reactstrap"
import "./dashboard.scss"
export const format_FAP = row => {
  return (
    <div>
      <span
        data-toggle="tooltip"
        data-placement="top"
        title={`${row.fbaSku}, ${row.asin}, ${row.name}`}
      >
        <a
          className="my-1"
          href={`https://sellercentral.amazon.com/skucentral?mSku=${row.fbmSku}&ref=myi_skuc`}
          target="_blank"
          rel="noreferrer"
        >
          {row.fbmSku ? row.fbmSku : ""}
        </a>
        <br />
        <a
          href={`https://www.amazon.com/dp/${row.asin}`}
          target="_blank"
          rel="noreferrer"
        >
          {row.asin ? row.asin : ""}
        </a>
        <br />
        <a
          href={`/products?sku=${row.fbaSku}`}
          target="_blank"
          rel="noreferrer"
        >
          {row.name
            ? `${row.name.slice(0, Math.min(120, row.name.length))}...`
            : ""}
        </a>
      </span>
    </div>
  )
}

export const format_FPI = row => {
  return (
    <div className="d-flex flex-column w-100 justify-content-center m-auto">
      <a
        className="my-1"
        href={`https://sellercentral.amazon.com/skucentral?mSku=${row.fbaSku}&ref=myi_skuc`}
        target="_blank"
        rel="noreferrer"
      >
        {row.fbaSku ? row.fbaSku : ""}
      </a>
      <div className="m-auto">
        {row.image ? (
          <img src={row.image} className="image" alt="product-image" />
        ) : null}
      </div>
    </div>
  )
}

export const format_CR = row => {
  const separatedText = row?.tag?.split(",")
  return (
    <div>
      {row.category ? row.category : ""}
      <br />
      {row.rank ? row.rank : ""}
      <br />
      {separatedText == "" ? (
        ""
      ) : (
        <div className="d-flex tag-width">
          {separatedText?.map((item, index) => {
            return (
              <span
                key={row.id}
                className="badge badge-secondary m-1 font-size-10"
                data-toggle="tooltip"
                title={`${item}`}
              >
                {item}
              </span>
            )
          })}
        </div>
      )}
    </div>
  )
}

export const format_FBCT = (row, value) => {
  return (
    <div>
      FBA: {row.fbaPrice ? "$" + row.fbaPrice : "$" + 0}
      <br />
      Fees: {row.fbaFees ? "$" + Number(row?.fbaFees).toFixed(2) : "$" + 0}
      <br />
      FBM: {row.fbmPrice ? "$" + row.fbmPrice : "$" + 0}
      <br />
      Fees: {row.fbmFees ? "$" + Number(row?.fbmFees).toFixed(2) : "$" + 0}
      <br />
      <span className="py-1" data-toggle="tooltip" title="Buy Box Price">
        BB : {row?.buyBoxPrice}
      </span>
    </div>
  )
}

export const format_OFBA = row => {
  return (
    <div className="format_OFBA">
      <Row className="d-flex flex-col">
        <Col className="col-6 col pr-1">
          3D: {row.fba3DaySales ? row.fba3DaySales : 0}
          <br />
          7D: {row.fba7DaySales ? row.fba7DaySales : 0}
          <br />
          14D: {row.fba14DaySales ? row.fba14DaySales : 0}
          <br />
        </Col>
        <Col className="col-6 col pr-1">
          30D: {row.fba30DaySales ? row.fba30DaySales : 0}
          <br />
          60D: {row.fba60DaySales ? row.fba60DaySales : 0}
          <br />
          90D: {row.fba90DaySales ? row.fba90DaySales : 0}
          <br />
        </Col>
      </Row>
      STR: {row.str ? row.str : ""}
    </div>
  )
}

export const format_OFBM = row => {
  return (
    <div>
      <Row className="d-flex flex-col">
        <Col className="col-6 col pr-1">
          3D: {row.fbm3DaySales ? row.fbm3DaySales : 0}
          <br />
          7D: {row.fbm7DaySales ? row.fbm7DaySales : 0}
          <br />
          14D: {row.fbm14DaySales ? row.fbm14DaySales : 0}
          <br />
        </Col>
        <Col className="col-6 col pr-1">
          30D: {row.fbm30DaySales ? row.fbm30DaySales : 0}
          <br />
          60D: {row.fbm60DaySales ? row.fbm60DaySales : 0}
          <br />
          90D: {row.fbm90DaySales ? row.fbm90DaySales : 0}
          <br />
        </Col>
      </Row>
      <div className="align-center my-1">{"(FBM)"}</div>
    </div>
  )
}

export const format_ATSI = row => {
  return (
    <div className="d-flex flex-column justify-content-between">
      <div className="d-flex flex-column justify-content-between pb-1">
        <span className="py-1" data-toggle="tooltip" title="Available">
          Aval: {row.availableQty ? row.availableQty : 0}
        </span>
        <span
          className="py-1"
          data-toggle="tooltip"
          title="Transfer + FC Process"
        >
          Tran: {row.transferQty ? row.transferQty : 0}
        </span>
        <span className="py-1" data-toggle="tooltip" title="In bound">
          Inbo: {row.inboundQty ? row.inboundQty : 0}
        </span>
      </div>
      <div className="border-top border-dark"></div>
      <div className="d-flex flex-column justify-content-between pt-1">
        <span className="py-1" data-toggle="tooltip" title="Total">
          Total: {row.totalQty ? row.totalQty : 0}
        </span>
      </div>
    </div>
  )
}

export const format_RFUO = row => {
  return (
    <div className="d-flex flex-column justify-content-between">
      <div className="d-flex flex-column justify-content-between pb-1">
        <span className="py-1" data-toggle="tooltip" title="Customer Orders">
          {" "}
          Orders: {row.pendingOrderQty ? row.pendingOrderQty : 0}
        </span>
      </div>
      <div className="d-flex flex-column justify-content-between pb-1">
        <span className="py-1" data-toggle="tooltip" title="FC Processing">
          {" "}
          FC Proc: {row.processingQty ? row.processingQty : 0}
        </span>
      </div>
      <div className="d-flex flex-column justify-content-between pb-1">
        <span className="py-1" data-toggle="tooltip" title="Unfulfillable">
          Unf: {row.unfilfillableQty ? row.unfilfillableQty : 0}
        </span>
      </div>
      <div className="d-flex flex-column justify-content-between pb-1">
        <span className="py-1" data-toggle="tooltip" title="Oversized">
          OV: {row.oversized}
        </span>
      </div>
    </div>
  )
}

export const format_DDD = row => {
  return (
    <div>
      <span className="py-1" data-toggle="tooltip" title="Days Remaining">
        DR : {row.daysRemaining ? row.daysRemaining : 0}
      </span>
      <br />
      Out: {row.daysOOS ? row.daysOOS : 0}
      <br />
      WPS: {row.wps ? row.wps.toFixed(1) : 0}
    </div>
  )
}

export const format_RA = row => {
  return (
    <div className="d-flex flex-column">
      {row.history
        ? row?.history?.map((data, index) => (
            <div key={row.id}>
              {`${new Date(data.date).toLocaleDateString("en-US", {
                month: "2-digit",
                day: "2-digit",
              })} : ${data.qty}`}
            </div>
          ))
        : ""}

      <div className="d-flex flex-row align-items-center my-2">
        <span data-toggle="tooltip" title="Sent">
          S: {row.shippedQty}
        </span>
        <span>/</span>
        <span data-toggle="tooltip" title="Received">
          R: {row.receivedQty}
        </span>
      </div>
    </div>
  )
}

export const format_UTM = (
  row,
  index,
  removeExpiryDate,
  handleExpiryDateSelect
) => {
  const dateOptions = { month: "2-digit", day: "2-digit", year: "numeric" }
  return (
    <div>
      Min: {row.suggestedUnitMin ? row.suggestedUnitMin : 0} (
      {row.minDays ? row.minDays : 0})<br />
      Max: {row.suggestedUnitMax ? row.suggestedUnitMax : 0} (
      {row.maxDays ? row.maxDays : 0}) <br />
      Exp Date:{" "}
      {/* {row.expiryDate ? new Date(row.expiryDate).toLocaleDateString("en-US", { month: "2-digit", day: "2-digit", year: "numeric" }) : "N/A"} */}
      {row.expiryDate ? (
        <div className="d-flex">
          <div>{row.expiryDate}</div>
          <i
            className="mdi mdi-close ms-2 pointer"
            data-toggle="tooltip"
            title="Remove Expiration Date"
            onClick={e => removeExpiryDate(e, row)}
          ></i>
        </div>
      ) : (
        <input
          type="date"
          name={`date-${index}`}
          id={`date-input-${index}`}
          className="p-1 w-100 tag-wrapper"
          style={{ borderRadius: "5px", border: "1px solid #BCD2E5" }}
          min={new Date().toISOString().split("T")[0]}
          onKeyDown={e => handleExpiryDateSelect(e, row)}
          onBlur={e => handleExpiryDateSelect(e, row)}
        />
      )}
    </div>
  )
}

export const format_FSA = (
  row,
  handleInput,
  updatedValue,
  handleAddProduct,
  handleDeleteProduct,
  index
) => {
  return (
    <div style={{ display: "contents" }}>
      <input
        type="text"
        className="w-50 ai-input-restock"
        name={`amount-${index}`}
        value={row.shipmentQty === 0 ? "" : row.shipmentQty}
        onChange={e => handleInput(e.target.value, row)}
        onKeyDown={e =>
          Number(updatedValue[row.id]?.qty) > 0
            ? handleAddProduct(e, index, "amount", row)
            : Number(updatedValue[row.id]?.qty) === 0 &&
              handleDeleteProduct(e, index, "amount", row)
        }
        onBlur={e =>{
          Number(updatedValue[row.id]?.qty) > 0
            ? handleAddProduct(e, index, "amount", row)
            : Number(updatedValue[row.id]?.qty) === 0 &&
              handleDeleteProduct(e, index, "amount", row)
          }
        }
      />
    </div>
  )
}

export const format_NOTES = (
  row,
  handleNotesInput,
  updatedValue,
  handleAddProduct,
  index
) => {
  return (
    <div className="align-center">
      <textarea
        rows="4"
        cols="25"
        name={`note-${index}`}
        className="w-100 ai-input-restock"
        value={row.notes === "null" ? "" : row.notes}
        onChange={e => handleNotesInput(e.target.value, row, e)}
        onKeyDown={e =>{
            updatedValue[row.id]?.notes !== null &&
            updatedValue[row.id]?.notes !== undefined &&
            updatedValue[row.id]?.notes.length > 0 &&
            handleAddProduct(e, index, "note", row)
          }
        }
        onBlur={e =>{
            //updatedValue[row.id]?.notes !== null &&
            //updatedValue[row.id]?.notes !== undefined &&
            //updatedValue[row.id]?.notes.length > 0 &&
            handleAddProduct(e, index, "notes", row)
          }
        }
      />
    </div>
  )
}

export const format_status = (row, changeStatus) => {
  return (
    <div className="d-flex flex-column align-items-center">
      <div className="form-check form-switch form-switch-md">
        <span data-toggle="tooltip" title={"Hide this product"}>
          <input
            type="checkbox"
            className="form-check-input"
            id={`${row.id}`}
            onClick={e => changeStatus(row)}
          />
        </span>
      </div>
      <span
        data-toggle="tooltip"
        title={row.statusLegend}
        className="color-div"
        style={{
          backgroundColor:
            row.status === "white"
              ? "#fff"
              : row.status === "grey"
              ? "#959ea7"
              : row.status === "red"
              ? "#fc0d1b"
              : row.status === "green"
              ? "#29fd2f"
              : "#FFEE00",
          border: row.status === "white" ? "1px solid #5c5c5c" : "",
        }}
      >
        <p
          className={
            row.status === "red" ? "red-status row-status" : "row-status"
          }
        >
          {row.statusLegend}
        </p>
      </span>
    </div>
  )
}
